const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.index-module__blocked___lLa6u {
  opacity: 0.9;
}

.index-module__disabled-component___2ebvS {
  opacity: 0.6;
  cursor: not-allowed;
}

/** do not use this yet */
.index-module__content___URziz {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 200px;
  position: relative;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s {
  padding: 20px 20px 10px 20px;
  border-bottom: 1px black solid;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__subHeader___WtWu7 {
  font-weight: bolder;
  margin: 0 0 20px 10px;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentList___qgcE5 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentList___qgcE5 .index-module__iconWrapper___TfdBU {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 65px;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentList___qgcE5 .index-module__iconWrapper___TfdBU .index-module__iconContainer___QsD-H {
  align-items: center;
  background-color: #DADADA;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  width: 55px;
  height: 55px;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentList___qgcE5 .index-module__iconWrapper___TfdBU label {
  font-size: 12px;
  text-align: center;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentGrid___28CL- {
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  gap: 20px;
  margin-bottom: 10px;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentGrid___28CL-.index-module__switchGrid___jM6n4 {
  grid-template-columns: repeat(auto-fit, 400px);
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentGrid___28CL-.index-module__sxSelectionGrid___j2wSw {
  grid-template-columns: repeat(auto-fit, 320px);
}
.index-module__content___URziz .index-module__componentBlock___tkN3s .index-module__componentGrid___28CL- .index-module__sxSelection___WNw-b {
  width: 100%;
  margin: 0;
}
.index-module__content___URziz .index-module__componentBlock___tkN3s.index-module__whiteBackground___aT7B7 {
  background-color: #ffffff;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "index-module__blocked___lLa6u",
  "disabled-component": "index-module__disabled-component___2ebvS",
  "content": "index-module__content___URziz",
  "componentBlock": "index-module__componentBlock___tkN3s",
  "subHeader": "index-module__subHeader___WtWu7",
  "componentList": "index-module__componentList___qgcE5",
  "iconWrapper": "index-module__iconWrapper___TfdBU",
  "iconContainer": "index-module__iconContainer___QsD-H",
  "componentGrid": "index-module__componentGrid___28CL-",
  "switchGrid": "index-module__switchGrid___jM6n4",
  "sxSelectionGrid": "index-module__sxSelectionGrid___j2wSw",
  "sxSelection": "index-module__sxSelection___WNw-b",
  "whiteBackground": "index-module__whiteBackground___aT7B7"
};