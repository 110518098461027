export const icons = [
  'sx-font-color',
  'sx-x-circle-rounded-corners',
  'sx-exclamation-point-solid',
  'sx-check-circle-filled',
  'sx-message-check',
  'sx-message-x',
  'sx-decimals',
  'sx-null',
  'sx-paintbrush',
  'sx-window',
  'sx-thumbs-up-filled',
  'sx-icon-comment',
  'sx-users-edit',
  'sx-sun',
  'sx-moon',
  'sx-tips',
  'sx-close-circle',
  'sx-message',
  'sx-package',
  'sx-signal',
  'sx-brand',
  'sx-brand-tracking',
  'sx-star-plus',
  'sx-upload-cloud',
  'sx-clipboard',
  'sx-highlight-cloud',
  'sx-flat-minus',
  'sx-text-highlighter',
  'sx-gabor-granger',
  'sx-manual-personas',
  'sx-siggy',
  'sx-pivot-table',
  'sx-survey',
  'sx-project-catalog',
  'sx-duplicate-plus',
  'sx-automated-personas',
  'sx-sightx-logo',
  'sx-impersonate-fill',
  'sx-add-user',
  'sx-analysis-refresh',
  'sx-toolbox-question',
  'sx-toolbox-custombuckets',
  'sx-toolbox-concepts',
  'sx-wallet',
  'sx-van-westendorp',
  'sx-settings',
  'sx-rocket',
  'sx-minimize',
  'sx-asterisk',
  'sx-pencil-edit',
  'sx-aggregation-graph',
  'sx-percent',
  'sx-comment-filled',
  'sx-quality-disqualify',
  'sx-disqualify',
  'sx-always-skip',
  'sx-add-logic',
  'sx-collapse-up',
  'sx-collapse-down',
  'sx-start-of-page',
  'sx-end-of-page',
  'sx-icon-conjoint',
  'sx-icon-experiment',
  'sx-question-page',
  'sx-welcome',
  'sx-info-square',
  'sx-copy',
  'sx-impersonate',
  'sx-card',
  'sx-move-x-axis',
  'sx-check',
  'sx-toolbar-align-left',
  'sx-toolbar-align-right',
  'sx-toolbar-align-center',
  'sx-bar-chart',
  'sx-data',
  'sx-file',
  'sx-home',
  'sx-fork',
  'sx-folder-closed',
  'sx-folder-open',
  'sx-compare',
  'sx-save',
  'sx-plus',
  'sx-toolbar-bold',
  'sx-quota',
  'sx-matrix',
  'sx-significance-testing',
  'sx-icon-personas',
  'sx-icon-questionpersona',
  'sx-star-shine',
  'sx-calendar-cross',
  'sx-calendar-minus',
  'sx-calendar-plus',
  'sx-send',
  'sx-bullseye',
  'sx-move-y-axis',
  'sx-hashtag',
  'sx-text-block',
  'sx-text',
  'sx-scale',
  'sx-grid',
  'sx-section',
  'sx-page',
  'sx-numeric',
  'sx-multiple-choice-image',
  'sx-max-diff',
  'sx-multiple-choice-number',
  'sx-list',
  'sx-multiple-choice',
  'sx-calendar-check',
  'sx-calendar',
  'sx-contact-info',
  'sx-external-link',
  'sx-concept-test',
  'sx-constant-sum',
  'sx-users',
  'sx-upload',
  'sx-time',
  'sx-table',
  'sx-sort-asc',
  'sx-sort-desc',
  'sx-plus-circle',
  'sx-pie',
  'sx-pencil',
  'sx-move',
  'sx-mobile',
  'sx-toolbar-underline',
  'sx-toolbar-strike',
  'sx-history',
  'sx-sign-out',
  'sx-window-close',
  'sx-sign-in',
  'sx-email',
  'sx-download',
  'sx-cross',
  'sx-icon-bar-stacked-graph',
  'sx-icon-flag',
  'sx-icon-bar-graph-horizontal',
  'sx-icon-scatter-graph',
  'sx-linear',
  'sx-icon-line-graph',
  'sx-averages',
  'sx-bars',
  'sx-archive',
  'sx-unarchive',
  'sx-skip-logic',
  'sx-merge',
  'sx-branch-logic',
  'sx-trash-open',
  'sx-trash',
  'sx-unlock',
  'sx-lock',
  'sx-filter',
  'sx-bookmark',
  'sx-reload',
  'sx-link',
  'sx-italic',
  'sx-redo',
  'sx-undo',
  'sx-require',
  'sx-collapse',
  'sx-expand-all',
  'sx-icon-youtube',
  'sx-icon-print',
  'sx-icon-megaphone',
  'sx-icon-map',
  'sx-toolbar-image',
  'sx-icon-info',
  'sx-group',
  'sx-icon-expand',
  'sx-chronometer',
  'sx-comment-outline',
  'sx-icon-book-open',
  'sx-zoom',
  'sx-icon-repeat',
  'sx-move-directions',
  'sx-icon-full-screen',
  'sx-translation',
  'sx-thumbs-up',
  'sx-star',
  'sx-heart',
  'sx-chevron-up',
  'sx-chevron-right',
  'sx-chevron-left',
  'sx-chevron-down',
  'sx-arrow-down',
  'sx-arrow-up',
  'sx-arrow-left',
  'sx-arrow-right',
  'sx-film',
  'sx-music',
  'sx-check-circle',
  'sx-delta',
  'sx-key',
  'sx-exclamation-triangle',
  'sx-icon-exclamation-point',
  'sx-icon-randomize',
  'sx-presentation',
  'sx-icon-responses',
  'sx-lines',
  'sx-share-arrow',
  'sx-icon-refresh',
  'sx-heatmap',
  'sx-balance',
  'sx-variable',
  'sx-pause',
  'sx-turf',
  'sx-play',
  'sx-eye-off',
  'sx-droplet',
  'sx-color',
  'sx-water',
  'sx-eye-open',
  'sx-add-to-presentation',
  'sx-duplicate',
  'sx-user-id-card',
  'sx-address-book',
  'sx-angry-fill',
  'sx-angry-outline',
  'sx-anova',
  'sx-binoculars',
  'sx-conditional-display',
  'sx-correlation',
  'sx-ellipsis',
  'sx-exchange',
  'sx-exclamation-triangle-solid',
  'sx-external',
  'sx-fixed',
  'sx-fullscreen',
  'sx-grouping',
  'sx-happy-fill',
  'sx-happy-outline',
  'sx-heart-solid',
  'sx-help-circle',
  'sx-icon-copy',
  'sx-icon-down',
  'sx-icon-function',
  'sx-icon-heat-maps',
  'sx-icon-image',
  'sx-icon-rating',
  'sx-icon-results',
  'sx-icon-search',
  'sx-icon-share',
  'sx-icon-x',
  'sx-microsoft-excel',
  'sx-minus',
  'sx-neutral-fill',
  'sx-neutral-outline',
  'sx-nps',
  'sx-promoter-entry',
  'sx-phone',
  'sx-question',
  'sx-question-circle',
  'sx-rank',
  'sx-rank-order',
  'sx-numeric-list',
  'sx-sad-fill',
  'sx-sad-outline',
  'sx-salesforce',
  'sx-search',
  'sx-search-lg',
  'sx-share',
  'sx-significant',
  'sx-smile-regular',
  'sx-smile-solid',
  'sx-spss',
  'sx-square',
  'sx-star-solid',
  'sx-survey-input-check-off',
  'sx-survey-input-check-on',
  'sx-survey-input-radio-off',
  'sx-survey-input-radio-on',
  'sx-switch',
  'sx-plain-text',
  'sx-thumbs-up-solid',
  'sx-toolbar-bullet',
  'sx-toolbar-color',
  'sx-toolbar-ordered',
  'sx-user',
  'sx-user-card',
  'sx-very-happy-fill',
  'sx-very-happy-outline',
  'sx-warning',
  'sx-word-file',
] as const;

export type IIcons = typeof icons[number];
